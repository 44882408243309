import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import VueI18n from 'vue-i18n';
import clipboard from 'clipboard';
//注册到vue原型上
import 'video.js/dist/video-js.css'

Vue.prototype.clipboard = clipboard;
Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);

Vue.use(VueI18n);
// let defaultLanguage = navigator.language || navigator.userLanguage;
// defaultLanguage = defaultLanguage.replace("-", "_").toLowerCase().trim();
let defaultLanguage = 'vi_vi';
if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", defaultLanguage);
}
const i18n = new VueI18n({
  globalInjection: true,
  locale: localStorage.getItem("lang") || defaultLanguage || "pt_pt", // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'th_th': require('./assets/languages/th_th.json'),
    'pt_pt': require('./assets/languages/pt_pt.json'),
    'vi_vi': require('./assets/languages/vi_vi.json'),    
    'zh_cn': require('./assets/languages/zh_cn.json'),
    'en_us': require('./assets/languages/en_us.json'),
    'es_spa': require('./assets/languages/es_spa.json'),
  }
});
router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
  document.title = i18n.t('title.'+to.matched[0].meta.title);
  next()
})
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
